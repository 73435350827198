<template>
        <v-col sm="12" cols="12" class="py-0">
            <LoadingMe
            :isLoading="showLoading"
            style="padding: 0"
            :fullPage="false"
            :myLoading="true" />
            <v-row >
                <v-col sm="4" cols="4" class="">
                    <label class="label">{{$t('issue_date')}}</label>
                    <app-datepicker
                        :initialDate="cr.issuedDate"
                        @emitDate="cr.issuedDate = $event"/>
                </v-col>
                <v-col sm="4" cols="4" class="">
                    <label class="label">{{$t('number')}}</label>
                    <v-row>
                        <v-col sm="10" cols="10" class="pr-0 pb-0 pt-1">
                            <v-text-field
                                v-model="cr.number"
                                outlined
                                :rules="[v => !!v || 'Number is required']"
                                readonly
                                required />
                        </v-col>
                        <v-col sm="2" cols="2" class="pl-0 pb-0 pt-1">
                            <v-icon color="black" size="30" class="border_qrcode" @click="getCrNumber">mdi-qrcode</v-icon>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col sm="4" cols="4" class="">
                    <label class="label">{{$t('close_by')}}</label>
                    <p>{{ countObj.countBy }}</p>
                </v-col>
                <v-col sm="4" cols="4" class="">
                    <v-card
                        style="margin-bottom: 2px"
                        outlined
                        dense
                        class="pa-3 no_border my_card rounded-4 white--text"
                        color="primary"
                        height="60px"
                    >
                        <h3 class="text-left font_13 flex-1 text-uppercase">
                        {{ $t("amount") }}
                        </h3>
                        <h3 class="text-right flex-1 font_20 niradei_heavy">
                        {{ numberFormat(cr.amount).toLocaleString() }}
                        </h3>
                    </v-card>
                </v-col>
                <v-col sm="4" cols="4" class="">
                    <v-card outlined
                        dense
                        class="pa-3 rounded-4 no_border white--text "
                        color="secondary"
                        height="60px">
                        <h3 class="text-left flex-1  font_12 text-uppercase">
                            {{ $t("variance") }}
                        </h3>
                        <h3 class="text-right flex-1 font_20">
                            {{ numberFormat(cr.varianceAmount).toLocaleString() }}
                        </h3> 
                    </v-card>
                </v-col>
                <v-col sm="4" cols="4" class="">
                    <v-card outlined
                        dense
                        class="pa-3 rounded-4 no_border white--text "
                        color="secondary"
                        height="60px">
                        <h3 class="text-left flex-1  font_12 text-uppercase">
                            {{ $t("actual_amount") }}
                        </h3>
                        <h3 class="text-right flex-1 font_20">
                            {{ numberFormat(cr.actualAmount).toLocaleString() }}
                        </h3>
                    </v-card>
                </v-col>
                <v-col sm="12" cols="12" class="">
                    <template>
                        <v-simple-table class="attachment_table">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th style="width: 50px"/>
                                        <th class="text-uppercase">{{ $t('currency') }}</th>
                                        <th class="text-uppercase">ក្រដាសប្រាក់</th>
                                        <th class="text-uppercase">{{ $t('unit') }}</th>
                                        <th class="text-uppercase">{{ $t('amount') }}</th>
                                        <th class="text-uppercase">{{ $t('exchange_rate') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr 
                                        v-for="d in noteDS" 
                                        v-bind:key="d.id"  
                                        >
                                        <td>
                                            <v-btn style="min-width: 0;" class="btn_edit_setting" @click="removeCRRow(d)">
                                                <v-icon
                                                    dark
                                                    right
                                                    style="margin-right:0;margin-left:0;"
                                                    color="white"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </v-btn>
                                        </td>
                                        <td>
                                            <v-select
                                                class="mt-1"
                                                v-model="d.currency"
                                                :items="currencies"
                                                item-value="name"
                                                item-text="name"
                                                @change="crChange"
                                                outlined
                                            />
                                        </td>
                                        <td>
                                            <v-text-field
                                            v-model="d.note"
                                            type="number"
                                            @change="crChange"
                                            required />
                                        </td>
                                        <td>
                                            <v-text-field
                                            v-model="d.unit"
                                            type="number"
                                            @change="crChange"
                                            required />
                                        </td>
                                        <td>
                                            <v-text-field
                                            v-model="d.amount"
                                            type="number"
                                            disabled
                                            required />
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model="d.exchangeRate"
                                                type="number"
                                                disabled
                                                required />
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </template>
                    <v-row style="background-color: #fff;">
                        <v-col sm="5" cols="12" class="pt-0">
                            <v-btn color="primary" class="float-left btn_plus rounded-0 white--text ml-0" @click="addCr">
                                <v-icon size="" class="ma-1">mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col sm="12" cols="4" class="pb-0">
                    <v-tabs>
                        <v-tab v-if="countObj.hasKHR">
                            <span  class="text-capitalize text-left">
                            {{ $t("kHR") }}
                            </span>
                        </v-tab>
                        <v-tab v-if="countObj.hasUSD">
                            <span class="text-capitalize text-left">
                            {{ $t("usd") }}
                            </span>
                        </v-tab>
                        <v-tab-item v-if="countObj.hasKHR">
                            <v-col sm="12" cols="12" class="grayBg">
                                <v-card color="white" class="pa-3 no_border text-center" elevation="0">
                                        <v-btn  @click="changeClicka('100r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                            100
                                        </v-btn>
                                        <v-btn  @click="changeClicka('200r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                            200
                                        </v-btn>
                                        <v-btn  @click="changeClicka('500r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                            500
                                        </v-btn>
                                        <v-btn  @click="changeClicka('1000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                            1,000
                                        </v-btn>
                                        <v-btn  @click="changeClicka('2000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                            2,000
                                        </v-btn>
                                        <v-btn  @click="changeClicka('5000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                            5,000
                                        </v-btn>
                                        <v-btn @click="changeClicka('10000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                            10,000
                                        </v-btn>
                                        <v-btn @click="changeClicka('15000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                            15,000
                                        </v-btn>
                                        <v-btn @click="changeClicka('20000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                            20,000
                                        </v-btn>
                                            <v-btn @click="changeClicka('30000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                            30,000
                                        </v-btn>
                                        <v-btn @click="changeClicka('50000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                            50,000
                                        </v-btn>
                                        <v-btn @click="changeClicka('100000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                            100,000
                                        </v-btn>
                                </v-card>
                            </v-col>
                        </v-tab-item>
                        <v-tab-item v-if="countObj.hasUSD">
                            <v-col sm="12" cols="12" class="grayBg">
                                <v-card color="white" class="pa-3 no_border text-center" elevation="0">
                                        <v-btn @click="changeClicka('1$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                            1$
                                        </v-btn>
                                        <v-btn @click="changeClicka('2$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                            2$
                                        </v-btn>
                                        <v-btn @click="changeClicka('5$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                            5$
                                        </v-btn>
                                        <v-btn @click="changeClicka('10$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                            10$
                                        </v-btn>
                                        <v-btn @click="changeClicka('20$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                            20$
                                        </v-btn>
                                        <v-btn @click="changeClicka('50$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                            50$
                                        </v-btn>
                                        <v-btn @click="changeClicka('100$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                            100$
                                        </v-btn>
                                </v-card>
                            </v-col>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
                <v-col sm="12" cols="12">
                    <div class="function_footer text-right">
                        <v-btn color="primary" class="float-right white--text text-capitalize"
                            @click="saveClose">
                            {{ $t('save') }}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-col>
</template>

<script>
// import { i18n } from "@/i18n";
import kendo from "@progress/kendo-ui"
const $ = kendo.jQuery
import { uuid } from "vue-uuid"
import DatePickerComponent from '@/components/custom_templates/DatePickerComponent'
const commerceHandler = require("@/scripts/commerce/handler/commerceHandler")
// import CashCountModel from "@/scripts/commerce/model/CashCount"
const cookieJS = require("@/cookie.js");
const { instituteId } = cookieJS.getCookie()
export default {
    name: "CashCount",
    props: {countObj: {}},
    data: () => ({
        hasKHR: false,
        hasUSD: false,
        activePin: {
            name: ''
        },
        miniVariant: false,
        kendo: kendo,
        decimal: 0,
        cr: {
            number: '',
            issuedDate: new Date().toISOString().substr(0, 10),
            varianceAmount: 0,
            actualAmount: 0,
            amount: 0,
            notes: []
        },
        noteDS: [],
        showLoading: false,
        txns: [],
        allCount: true,
        activeStore: localStorage.getItem(instituteId + 'commStore') != null ? JSON.parse(localStorage.getItem(instituteId + 'commStore')) : {},
        lastNumber: 1,
        saveType: ''
    }),  
    methods: {
        async getCrNumber(){
            this.showLoading = true
            let num = 1
            let data = {
                sk: instituteId,
                pk: 'comm-cashcount'
            }
            await commerceHandler.generalGet(data).then(res=>{
                this.showLoading = false
                if(res.data.data.Items.length > 0){
                    num = parseInt(res.data.data.Items[0].lastNumber)
                }
            })
            this.lastNumber = num
            this.generateNumber(num)
        },
        generateNumber(num){
            this.cr.number = 'CC/' + this.zeroPad(num, 6);
        },
        zeroPad(num, places) {
            return String(num).padStart(places, "0");
        },
        numberFormat(value) {
            return kendo.toString(parseFloat(value), `n${this.decimal}`);
        },
        crChange(){
            // window.console.log(this.noteDS, this.countObj.secondRate, 'cr change')
            let amt = 0
            this.noteDS.forEach(n=>{
                n.amount = parseInt(n.note) * parseInt(n.unit)
                n.exchangeRate = n.amount
                if(n.currency == this.countObj.secondRate.name){
                    n.exchangeRate = parseFloat(this.countObj.secondRate.rate) < 1 ? n.amount * parseFloat(this.countObj.secondRate.rate) : n.amount / parseFloat(this.countObj.secondRate.rate)
                }
                amt += n.exchangeRate
                n.amount = (n.amount).toFixed(this.decimal)
                n.exchangeRate = (n.exchangeRate).toFixed(this.decimal)
            })
            this.cr.amount = amt
            this.cr.varianceAmount = parseFloat(this.cr.actualAmount) - amt
        },
        addCr(){
            this.noteDS.push({
                id: uuid.v1(),
                currency: '',
                note: 0,
                unit: 0,
                rate: 0,
                amount: 0,
                exchangeRate: 0
            })
        },
        changeClicka(data){
            let amt;
            let c;
            switch(data) {
                case '1$' :
                    amt = 1;
                    c = 'US Dollar'
                    break;
                case '2$' :
                    amt = 2;
                    c = 'US Dollar'
                    break;
                case '5$' :
                    amt = 5;
                    c = 'US Dollar'
                    break;
                case '10$' :
                    amt = 10;
                    c = 'US Dollar'
                    break;
                case '20$' :
                    amt = 20;
                    c = 'US Dollar'
                    break;
                case '50$' :
                    amt = 50;
                    c = 'US Dollar'
                    break;
                case '100$' :
                    amt = 100;
                    c = 'US Dollar'
                    break;
                case '100r' :
                    amt = 100;
                    c = 'Cambodian Riel'
                    break;
                case '200r' :
                    amt = 200;
                    c = 'Cambodian Riel'
                    break;
                case '500r' :
                    amt = 500;
                    c = 'Cambodian Riel'
                    break;
                case '1000r' :
                    amt = 1000;
                    c = 'Cambodian Riel'
                    break;
                case '2000r' :
                    amt = 2000;
                    c = 'Cambodian Riel'
                    break;
                case '5000r' :
                    amt = 5000;
                    c = 'Cambodian Riel'
                    break;
                case '10000r' :
                    amt = 10000;
                    c = 'Cambodian Riel'
                    break;
                case '15000r' :
                    amt = 15000;
                    c = 'Cambodian Riel'
                    break;
                case '20000r' :
                    amt = 20000;
                    c = 'Cambodian Riel'
                    break;
                case '30000r' :
                    amt = 30000;
                    c = 'Cambodian Riel'
                    break;
                case '50000r' :
                    amt = 50000;
                    c = 'Cambodian Riel'
                    break;
                case '100000r' :
                    amt = 100000;
                    c = 'Cambodian Riel'
                    break;
                default:
                    break;
            }
            this.addCrCurrency(amt, c)
        },
        removeCRRow(e) {
            const index = this.noteDS.indexOf(e);
            this.noteDS.splice(index,1);
            this.crChange()
        },
        addCrCurrency(amt, currency){
            let h = false 
            this.noteDS.forEach(n=>{
                if(n.currency == currency && n.note == amt){
                    h = true 
                    n.unit += 1
                }
            })
            if(!h){
                this.noteDS.push({
                    id: uuid.v1(),
                    currency: currency,
                    note: amt,
                    unit: 1,
                    rate: 0,
                    amount: 0,
                    exchangeRate: 0
                })
            }
            this.crChange()
        },
        loadTxn(key){
            window.console.log(this.countObj, 'count obj')
            this.txns = []
            let d = {
                epsAtt: {
                    ':sesPk': this.countObj.pk + '/cashcount',
                    ':startDate': new Date(this.countObj.startDate).getTime().toString(),
                    ':endDate': new Date(this.countObj.endDate).getTime().toString(),
                },
                index: 'sesPk-searchDate-index',
                keyCondition: 'sesPk = :sesPk and searchDate between :startDate and :endDate',
                key: key
            }
            this.showLoading = true
            this.allCount = true
            commerceHandler.reportGets(d).then(res => {
                this.showLoading = false
                let noCashCount = false
                let  data = res.data.data.Items
                if(data.length > 0){
                    let total = 0
                    data.forEach(dd=>{
                        total += parseFloat(dd.amount)
                    })
                    if(total > 0){
                        this.cr.actualAmount = total
                    }else{
                        noCashCount = true
                    }
                }else{
                    noCashCount = true
                }
                if(noCashCount){
                    this.$snotify.error('No sale!')
                    this.allCount = false
                    this.closeCashCount()
                }else{
                    this.addCr()
                }
                window.console.log(res, 'sale report')
            })
        },
        closeCashCount(){
            $("#closeCountCash").click();
        }, 
        initData(){
            if(Object.keys(this.countObj).length > 0){
                window.console.log(this.countObj, 'count obj')
                if(Object.keys(this.countObj.info).length > 0){
                    this.cr = this.countObj.info
                }else{
                    this.getCrNumber()
                }
                if(this.countObj.notes.length > 0){
                    this.noteDS = this.countObj.notes
                }
                if(this.countObj.startDate != '' && this.countObj.endDate != ''){
                    // this.loadTxn({})
                    let noCashCount = false
                    let total = this.countObj.totalCash
                    if(total > 0){
                        this.cr.actualAmount = total
                    }else{
                        noCashCount = true
                    }
                    if(noCashCount){
                        this.$snotify.error('No sale!')
                        this.allCount = false
                        this.closeCashCount()
                    }else{
                        this.addCr()
                    }
                }
            }
        },
        saveDraft(){
            this.saveType = 'saveDraft'
            this.save()
        },
        saveClose(){
            this.saveType = 'saveClose'
            this.save()
        },
        save(){
            if(this.noteDS.length <= 0){
                this.$snotify.error('notes_required!')
                return
            }
            window.console.log(this.noteDS, 'notes list')
            let notes = []
            this.noteDS.forEach(n=>{
                if(n.currency != "" && parseInt(n.unit) > 0){
                    notes.push({
                        id: n.id,
                        currency: n.currency,
                        note: n.note,
                        unit: n.unit,
                        rate: n.rate,
                        amount: n.amount,
                        exchangeRate: n.exchangeRate
                    })
                }
            })
            if(notes.length <= 0){
                this.$snotify.error('notes_required!')
                return
            }
            // this.countObj.notes = notes
            let c = this.countObj
            let data = {
                sessionId: c.pk,
                countBy: c.countBy,
                notes: notes,
                info: this.cr,
                lastNumber: this.lastNumber,
                saveType: this.saveType
            }
            this.showLoading = true
            commerceHandler.cashCountCreate(data).then(()=>{
                this.showLoading = false
                this.$snotify.success('sucessfull')
                this.closeCashCount()
            })
        },
    },
    components: {
        'app-datepicker': DatePickerComponent,
        LoadingMe: () => import(`@/components/Loading`),
    },
    computed:{
        currencies() {
            if (this.countObj) {
                if (this.countObj.hasOwnProperty('currencies')) {
                    return this.countObj.currencies
                }
            }
            return []
        },
    },
    created: async function () {
        this.initData()
    },
};
</script>
<style scoped>
    #pin > .theme--light.v-input input {
        max-height: 32px;
        font-size: 2rem !important;
        text-align: center;
    }
    .v-image__image--cover {
        background-size: contain;
        margin-top: 5px;
    }
    .v-image__image {
        background-position: top center !important;
    }
    .b-cash:before {
        color: #ffffff;
    }   
    .b-loyalty:before {
        content: "\e91b";
        color: #ffffff;
    }
    .btn-right .v-btn__content i{
       font-size: 40px;
    }
    .btn-right .v-btn__content{
       display: block !important;
    }
    h6{
       color: #2a2b2b;
       font-size: 0.6rem !important; 
       font-family: "Niradei-bold", serif !important;
       padding-top: 5px;
    }
    
    .btn-right{
        background-color: #ffffff !important;
        border-bottom: 1px solid;
        border-color: #e5e5e6 !important;
        width: 100%;
        height: 12% !important;
        text-align: center;
        padding: 0 3px !important;
        border-radius: 0px !important;  
    }
    .v-tab {
        justify-content: left;
        font-size: 20px;
    }

    .v-tab--active {
        background-color: rgb(255, 255, 255);
    }

    .tab_setting .v-tab--active {
        font-weight: 700;
        color: #000;
    }

    .v-tab--active {
        background-color: #ffffff !important;
        border-bottom: 4px solid #92d050;
        border-left: none;
    }

    p {
        color: rgba(0, 0, 0, 0.87);
    }
    .theme--light.v-tabs-items {
        background-color: #FFFFFF;
        height: 550px !important;
        overflow: scroll !important;
    }
    
    .sale-container{
        max-width: 1870px !important;
    }
    .theme--light.v-tabs-items {
        background-color: #ffffff00 !important;
    }
    .card-item{
        background-color: #ffffff00;
        border: 0.2px solid #dcdcdc33;
        border-radius: 0;
    }
    .catagory-btn{
        width: 100%;
        background-color: #ffffff !important;
        border: 0.2px solid #e4e4e6;
    }
    .v-btn__content{
        font-family: "Niradei-Bold", serif !important;
        display: block !important;
    }
    .btn_h2{
        font-size: 22px;
    }
    .sidebar-left{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 12.666667%;
        flex: 0 0 12.666667%;
        max-width: 12.666667%;
    }
    .sidebar-left2{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 38.666667%;
        flex: 0 0 38.666667%;
        max-width: 38.666667%;
    }
    .sidebar-left3{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 7.000000%;
    flex: 0 0 7.000000%;
    max-width: 7.000000%;
    }
    .container h2 {
        font-size: 17px;
        margin-bottom: 0;
    }
    .btn-sidebar{
        justify-content: space-between !important;
        background-color: #fff !important;
        font-family: "Niradei-bold", serif !important;
    }

    .b-payment:before {
        content: "\e91f";
        color: #ffffff;
    }
    .b-banking:before {
        content: "\e90e";
        color: #ffffff;
    }
    .btn-money {
        background-color: #969696 !important;
        color: white;
    }
    
    .calculator{
        border: 1px solid gainsboro;
    }
    .receipt-btn{
        min-width: 22% !important;
        height: 50px !important;
        padding: 10px !important;
        margin: 5px;
    }
    .apply-btn{
        min-width: 33% !important;
        height: 50px !important;
        padding: 5px !important;
        margin: 5px;
    }
    .calculator-btn{
       min-width: 18% !important;
        margin: 4px;
        padding: 0 10px !important;
    }
    .calculator-btn-1{
        min-width: 20% !important;
        margin: 4px;
        padding: 0 2px !important;
        font-size: 13px !important;
        letter-spacing: inherit;
        font-weight: bold;
    }
    .pay-btn{
        width: 100%;
        border: 1px solid #e0e0e0;
    }
    .v-text-field__detail .field-pos{
        display:none !important;
    }
    .btn-funtion{
        font-size: 14px;
        width: 100% !important;
        display: flex;
        justify-content: space-between;
    }
    .function_content {
        padding: 0px;
        border-bottom: none !important;
        background-color: #f8f8f9;
    }
    .notification{
        background-color: #ed263a;
        width: auto;
        margin-left: 2px;
        color: #fff;

    }
    .v-btn.v-size--default {
        font-size: 1.2rem;
    }
    .b-search.b:before {
        color: #d7d3d3 !important;
    }
    /* pin */
    body {
      height: 95vh;
      background-color: #181c26 !important;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-family: Open Sans;
    }

    body.wrong {
      -webkit-animation: bg-red 1s ease-in;
      animation: bg-red 1s ease-in;
    }

    body.correct {
      -webkit-animation: bg-green 1s ease-in;
      animation: bg-green 1s ease-in;
    }

    #inspiration {
      position: fixed;
      right: 1em;
      bottom: 1em;
    }

    #inspiration a {
      display: inline-block;
      text-decoration: none;
      font-weight: bold;
      color: white;
      -webkit-transition: all 1s ease;
      transition: all 1s ease;
    }

    #inspiration a:hover { color: #212121; }

    #inspiration p {
      margin: 0;
      padding-left: .4em;
      display: inline-block;
      color: rgba(255, 255, 255, 0.6);
    }
    #pin {
        background-color: #ffffff !important;
        width: 90%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        /* padding: 1em; */
        border-radius: .3em;
        /* box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3); */
        margin: auto;
        color: rgb(155 27 46);;
        }

    .dots {
      width: 50%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      padding: 1em;
      padding-top: 3em;
    }

    .dot {
      position: relative;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 0.8em;
      width: 0.8em;
      height: 0.8em;
      -webkit-transform: scale3d(0.7, 0.7, 0.7);
      transform: scale3d(0.7, 0.7, 0.7);
    }

    .dot.active {
      -webkit-animation: growDot .5s ease;
      animation: growDot .5s ease;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    .dot.wrong {
      -webkit-animation: wrong .9s ease;
      animation: wrong .9s ease;
    }

    .dot.correct {
      -webkit-animation: correct .9s ease;
      animation: correct .9s ease;
    }

    .cancelPin {
      width: 25%;
      margin-left: 10%;
      margin-top: 10%;
    }
    .letter_spacing{
        letter-spacing:initial;
        font-size: 12px !important;
    }

    #pin p { font-size: 1.2em; }

    .numbers {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
      align-content: flex-end;
      margin: 1em 0;
    }

    .number {
      position: relative;
      width: 2.5em;
      height: 2.5em;
      margin: 0.5em;
      border-radius: 2.5em;
      border: 2px solid rgb(154 27 46);
      text-align: center;
      line-height: 2.5em;
      font-weight: 400;
      font-size: 1.8em;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
      cursor: pointer;
    }

    .number:hover { color: rgba(243, 134, 134, 0.5); }

    .number:hover:before { border: 2px solid rgba(255, 255, 255, 0.5); }

    .number:before {
      content: "";
      position: absolute;
      left: -2px;
      width: 2.5em;
      height: 2.5em;
      border: 2px solid rgba(255, 255, 255, 0.1);
      border-radius: 2.5em;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
    }
    .number.grow:before {
        -webkit-animation: grow .6s ease;
        animation: grow .6s ease;
        }
    @-webkit-keyframes 
    growDot {  100% {
    background: white;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
    }
    }
    @keyframes 
    growDot {  100% {
    background: white;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
    }
    }
    @-webkit-keyframes 
    grow {  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    }
    @keyframes 
    grow {  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    }
    @-webkit-keyframes 
    wrong {  20% {
    background: crimson;
    }
    40% {
    -webkit-transform: translate(-15px, 0);
    transform: translate(-15px, 0);
    }
    60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
    }
    80% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
    }
    }
    @keyframes 
    wrong {  20% {
    background: crimson;
    }
    40% {
    -webkit-transform: translate(-15px, 0);
    transform: translate(-15px, 0);
    }
    60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
    }
    80% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
    }
    }
    @-webkit-keyframes 
    correct {  20% {
    background: limegreen;
    }
    40% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    }
    60% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    }
    80% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
    }
    }
    @keyframes 
    correct {  20% {
    background: limegreen;
    }
    40% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    }
    60% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    }
    80% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
    }
    }
    @-webkit-keyframes 
    bg-red {  50% {
    background: crimson;
    }
    }
    @keyframes 
    bg-red {  50% {
    background: crimson;
    }
    }
    @-webkit-keyframes 
    bg-green {  50% {
    background: limegreen;
    }
    }
    @keyframes 
    bg-green {  50% {
    background: limegreen;
    }
    }
    #pin >.v-input input {
        text-align: center !important;
        font-size: 35px !important;
    }
    .td-invioce td{
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .tb-pong td{
        border-bottom: none !important;
        height: 25px !important;
    }
    .th-invoice th{
      border-top: thin solid rgba(0, 0, 0, 0.12) !important;  
    }
    .v-list-item-left {
        padding: 0 10px !important;
    }
    .theme--light.v-divider{
        border-color: rgb(0 0 0 / 0%);
    }


    /* Surface pro */
    @media only screen and (min-width : 1400px){
        .btn-funtion{
            font-size: 14px !important;
        }
        .v-application--is-ltr .v-btn__content .v-icon--left {
            margin-right: 8px;
        }
        .v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
            font-size: 18px;
            height: 18px;
            width: 18px;
        }
        .calculator-btn{
            font-size: 14px;
        }
        
    }
   @media only screen and (min-device-height : 720px) and (max-device-width : 1280px) {
    
        /** Surface Pro styles here **/
        .btn-funtion{
            font-size: 12px !important;
        }
        .v-application--is-ltr .v-btn__content .v-icon--left {
            margin-right: 5px;
        }
        .v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
            font-size: 16px;
            height: 10px;
            width: 10px;
        }
        .calculator-btn{
            font-size: 12px;
        }
    }
    .angle_action {
        right: 0;
        z-index: 10;
        position: absolute;
        top: 60px;
    }
    .angle_action_small {
        right: 12px;
        z-index: 10;
        position: absolute;
        top: 60px;
    }
    .b-order_type:before {
        content: "\e933";
        color: #ffffff;
        font-size: 22px;
    }
    .b-reward_s:before {
        content: "\e937";
        color: #ffffff;
        font-size: 22px;
    }
    .b-promotion:before {
        content: "\e936";
        color: #ffffff;
        font-size: 22px;
    }
    .b-count_guest:before {
        content: "\e935";
        color: #ffffff;
        font-size: 22px;
    }
    .b-note_s:before {
        content: "\e932";
        color: #ffffff;
        font-size: 22px;
    }
    .b-delivery_s:before {
        content: "\e931";
        color: #ffffff;
        font-size: 22px;
    }
    .b-parksale:before {
        content: "\e934";
        color: #ffffff;
        font-size: 22px;
    }
    .b-invoice_s:before {
        content: "\e930";
        color: #ffffff;
        font-size: 22px;
    }

</style>